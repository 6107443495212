@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}
